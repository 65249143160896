import RemConverter from "./utils/remConverter";
import clutchLogo from "@images/clutch-logo.svg";
import clutchTelecommunicationLogo from "@images/clutch-telecommunication.svg";
import clutchGlobalSpringLogo from "@images/clutch-global-spring-logo.svg";
import clutchGenerativeAiLogo from "@images/clutch-generative-ai-logo.svg";
import goodFirmsLogo from "@images/good-firms.svg";
import wadlineLogo from "@images/wadline-logo.svg";
import upcityLogo from "@images/upcity-logo.svg";
import manifestLogo from "@images/manifest-logo.svg";
import developersLogo from "@images/developers-logo.svg";
import techUKLogo from "@images/tech-uk-logo.svg";
import lvivITClusterLogo from "@images/lviv-it-cluster-logo.svg";
import itUkraineAssociationLogo from "@images/it-ukraine-association-logo.svg";
import forbesLogo from "@images/forbes-logo.svg";
import { GLOBAL_APPLICATION_EXTERNAL_LINKS } from "./links/external";

export const MAIN_BUTTON = "main-button";
export const SECONDARY_BUTTON = "secondary-button";
export const DEFAULT_SIZE_BUTTON = "default-size";
export const HEADER_MAIN_BUTTON = "header-main-button";
export const HEADER_SECONDARY_BUTTON = "header-secondary-button";
export const SMALL_SIZE_BUTTON = "small-size";

export const NOT_FOUND_PAGE_PATH = "/404";
export const CONTACT_US_PAGE_PATH = "/contact-us";
export const ABOUT_PAGE_PATH = "/about";
export const CUSTOM_PRODUCT_DEVELOPMENT = "/custom-product-development";
export const PRODUCT_DEVELOPMENT_START = "/product-development-start";
export const GDPR_PAGE_PATH = "/gdpr";
export const PRIVACY_POLICY_PAGE_PATH = "/privacy-policy";
export const DEDICATED_TEAMS_PAGE_PATH = "/dedicated-teams";
export const MOBILE_DEVELOPMENT_PATH = "/mobile-development";
export const WEB_DEVELOPMENT_PAGE_PATH = "/web-development";

export const THANK_YOU_PATH = "/thank-you";
export const BLOG_PAGE_PATH = "/blog";
export const OFFICE_PATH = "/office";
export const CAREERS_PATH = "/careers";

export const WRAP_LIST = "wrap-list";

export const CATCHUP_SECTION_LEFT = "left";
export const CATCHUP_SECTION_CENTER = "center";

export const CATCHUP_SECTION_BLUR_HEIGHT = "457px";
export const CATCHUP_SECTION_BLUR_WIDTH = "1114px";

export const CASE_STUDY_TEXT_DEFAULT = "text-default";
export const CASE_STUDY_TEXT_UP = "text-up";
export const CASE_STUDY_TEXT_HIGHER = "text-higher";

export const PROCESS_SECTION_BORDER_HEIGHT = 350;

export const CASE_STUDY_IMAGE_CENTER = "image-center";
export const CASE_STUDY_IMAGE_DEFAULT = "image-default";

export const HEADER_HEIGHT = RemConverter.convertRemToPixels(6.5);
export const HEADER_OFFSET_Y = 55;
export const SUPPORT_BANNER_HEIGHT = 4.6875;

export const LAYOUT_PADDING_TOP = 236;

export const PROCESS_SECTION_ITEM_HEIGHT =
    RemConverter.convertRemToPixels(21.875);

export const TEXT_SIZE_DEFAULT = "default";
export const TEXT_SIZE_BIG = "big";

export const EMAIL_VALIDATION_REGEXP =
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const CLIENTS = {
    G_NETWORK: "gnetwork"
};

export const LINKS = {
    services: [
        {
            linkName: "Product development",
            path: "/product-development",
        },
        {
            linkName: "Dedicated teams",
            path: "/dedicated-teams",
        },
    ],
    subServices: [
        {
            linkName: "Node.js",
            path: "/product-development",
        },
        {
            linkName: "React",
            path: "/dedicated-teams",
        },
    ],
    expertise: [
        {
            linkName: "AI/ML development",
            path: "/artificial-intelligence",
        },
        {
            linkName: "Extended reality",
            path: "/extended-reality",
        },
        {
            linkName: "Product discovery",
            path: "/discovery-phase",
        },
        {
            linkName: "UI/UX design",
            path: "/design",
        },
        {
            linkName: "Mobile app development",
            path: "/mobile-development",
        },
        {
            linkName: "Web app development",
            path: "/web-development",
        },
        {
            linkName: "DevOps engineering",
            path: "/devops",
        },
        // for now commented
        // {
        //     linkName: "IT Consulting",
        //     path: "/devops",
        // },
    ],
    company: [
        {
            linkName: "Case studies",
            path: "/case-studies",
        },
        {
            linkName: "About us",
            path: "/about",
        },
        {
            linkName: "Blog",
            path: "/blog",
        },
        {
            linkName: "Careers",
            path: "/careers",
        },
        // for now commented
        // {
        //     linkName: "Our office",
        //     path: "/office",
        // },
    ],
    industry: [
        {
            linkName: "Healthcare",
            path: "/healthcare",
        },
        {
            linkName: "Telecommunication",
            path: "/telecommunication",
        },
        {
            linkName: "Internet service providers",
            path: "/internet-service-providers",
        },
        {
            linkName: "Real estate",
            path: "/real-estate",
        },
        {
            linkName: "Fintech",
            path: "/fintech",
        },
    ],
    rest: [
        {
            linkName: "Contact us",
            path: "/contact-us",
        },
    ],
};

export const MOBILE_MAX_WIDTH = 1350;
export const MOBILE_MIN_WIDTH = 767;

// export const MOBILE_MIN_WIDTH = 320;
// export const MOBILE_MAX_WIDTH = 767;
export const TABLET_MIN_WIDTH = 768;
export const TABLET_MAX_WIDTH = 1350;

export const DESKTOP_MIN_WIDTH = 1050;

export const HUGE_SCREEN_MIN_WIDTH = 1980;

export const EXPERTISE_ITEM_WIDTH = 500;

export const SLIDER_WITH_IMAGES = "slider-with-images";
export const SLIDER_WITH_FEEDBACK = "slider-with-feedback";

export const SHIFTED_IMAGES_COLLAGE = "shifted";
export const UNSHIFTED_IMAGES_COLLAGE = "unshifted";

export const REVERSE_IMAGES_COLLAGE = "reverse-images-collage";
export const DEFAULT_IMAGES_COLLAGE = "default-images-collage";

export const TOOLTIP_ID = "blogToolTip";
export const TOOLTIP_WIDTH = 94;
export const TOOLTIP_HEIGHT = 38;

export const BLOG_START_YEAR = 20;

export const MAC_OS_DESIGNATION = "mac";

export const HURMA_TOKEN =
    "27d8ee7fb30a3bf492e9c6df79d89df28fb481915001b63a551992bf24b058607c25f0bc95c22ee";
export const EMPTY_VACANCY_IDS = [26, 80];

export const MIN_ARTICLES_QUANTITY = 5;

export const youtubeUrlRegex =
    // eslint-disable-next-line
  /((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)/gm

export const CALL_TO_ACTION_NAVIGATION_TOPICS = {
    productDevelopment: "Product development",
    dedicatedTeams: "Dedicated teams",
    mobileAppDevelopment: "Mobile app development",
    webAppDevelopment: "Web app development",
};

export const BANNER_BLOG_MARKUP_PROPS = {
    title: "title",
    description: "description",
    buttonText: "buttonText",
    buttonNavigationPath: "buttonNavigationPath",
    imageUrl: "imageUrl",
    alt: 'alt'
};

export const MEMBERSHIP_BADGES = {
    techUK: {
        href: GLOBAL_APPLICATION_EXTERNAL_LINKS.TECH_UK,
        src: techUKLogo
    },
    lvivITCluster: {
        href: GLOBAL_APPLICATION_EXTERNAL_LINKS.LVIV_IT_CLUSTER,
        src: lvivITClusterLogo
    },
    ITUkraineAssociation: {
        href: GLOBAL_APPLICATION_EXTERNAL_LINKS.IT_UKRAINE_ASSOCIATION,
        src: itUkraineAssociationLogo
    },
    forbes: {
        href: GLOBAL_APPLICATION_EXTERNAL_LINKS.FORBES,
        src: forbesLogo
    }
}

export const BADGES = {
    clutchFastestGrowth: {
        href: GLOBAL_APPLICATION_EXTERNAL_LINKS.CLUTCH_IMPRESSIT,
        src: clutchLogo
    },
    clutchTelecommunication: {
        href: GLOBAL_APPLICATION_EXTERNAL_LINKS.CLUTCH_IMPRESSIT,
        src: clutchTelecommunicationLogo
    },
    goodFirms: {
        href: GLOBAL_APPLICATION_EXTERNAL_LINKS.GOODFIRMS_IMPRESSIT,
        src: goodFirmsLogo
    },
    wadline: {
        href: GLOBAL_APPLICATION_EXTERNAL_LINKS.WADLINE_IMPRESSIT,
        src: wadlineLogo
    },
    upcity: {
        href: GLOBAL_APPLICATION_EXTERNAL_LINKS.UPCITY_IMPRESSIT,
        src: upcityLogo
    },
    manifest: {
        href: GLOBAL_APPLICATION_EXTERNAL_LINKS.THE_MANIFEST_IMPRESSIT,
        src: manifestLogo
    },
    developers: {
        href: GLOBAL_APPLICATION_EXTERNAL_LINKS.TOP_DEVELOPERS_IMPRESSIT,
        src: developersLogo
    },
    clutchGlobalSpring: {
        href: GLOBAL_APPLICATION_EXTERNAL_LINKS.CLUTCH_IMPRESSIT,
        src: clutchGlobalSpringLogo
    },
    clutchGenerativeAi: {
        href: GLOBAL_APPLICATION_EXTERNAL_LINKS.CLUTCH_IMPRESSIT,
        src: clutchGenerativeAiLogo
    }
}

export const youtubeIframeMarkup = youtubeLinkId =>
    `<iframe title="Youtube video - ${youtubeLinkId}" width="100%" height="400" src="//www.youtube.com/embed/${youtubeLinkId}" frameBorder="0" allowFullScreen></iframe>`;

