export default class RemConverter {
    static convertRemToPixels(rem) {
        const isDoc = typeof document !== "undefined";
        if (isDoc) {
            return (
                rem *
                parseFloat(getComputedStyle(document.documentElement).fontSize)
            );
        }
        return 0;
    }

    static convertPixelsToRem(pixels) {
        const isDoc = typeof document !== "undefined";
        if (isDoc) {
            return (
                pixels /
                parseFloat(getComputedStyle(document.documentElement).fontSize)
            );
        }
        return 0;
    }
}
